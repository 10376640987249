import { Locales } from "./locales";

import en from "./en.json";
import fr from "./fr.json";
import nl from "./nl.json";

export const messages = {
    [Locales.EN]: en,
    [Locales.FR]: fr,
    [Locales.NL]: nl,
};

export const defaultLocale = Locales.EN;