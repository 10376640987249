import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import VueI18n from 'vue-i18n';
import { messages, defaultLocale } from "@/i18n";
import VueHead from 'vue-head'

Vue.use(VueHead)

Vue.filter('capitalize', function (value: string = '') {
  return value.charAt(0).toUpperCase() + value.slice(1)
})
Vue.use(VueI18n);
const i18n = new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
});

Vue.config.productionTip = false
new Vue({
  i18n,
  render: h => h(App)
}).$mount('#app')
