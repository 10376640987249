






import { Component, Vue } from "vue-property-decorator";
import ClickSurvey from "./components/ClickSurvey.vue";

@Component({
  components: {
    ClickSurvey,
  },
})
export default class App extends Vue {}
