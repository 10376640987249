





































































































































































































































































































/* http://localhost:8081/?s=60589d6a0b538f050572f8de&i=5fb2628cddb132c6d7e0c3ff */

import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";
// @ts-ignore
import antonyms from "../antonyms";
// @ts-ignore
import createUUID from "../uuid";

// declare module "*";

@Component
export default class ClickSurvey extends Vue {
  // @Prop() private msg!: string;

  ready: boolean = false;
  debug: boolean = false;
  step: string = "hello";
  survey: any = null;
  matches: Array<any> = [];
  current: any = {};
  surveyId: any = undefined;
  itemId: any = undefined;
  // lang: any = undefined;
  thematics: string[] = [];
  totalClicks: number = 0;
  startTime: number = 0;
  endTime: number = 0;
  timing: number = 0;
  antonyms: object = antonyms;
  uuid: string = ""; // each restart
  suuid: string = ""; //once per app load
  opentext: string = "";
  param_lang: string = "";


  // my own crappy version of $t because i cannot overwritte strings once the component is loaded...
  // does not support the second param if overwritten
  tt() {
    var string = arguments[0];
    var options = arguments[1];
    var path = this.$i18n.locale + "." + string;
    try {
      var res = this.survey.strings[this.$i18n.locale][string];
      if (res) {
        return res;
      } else {
        throw true;
      }
    } catch (e) {
      if (options) {
        return this.$t(string, options);
      } else {
        return this.$t(string);
      }
    }
    // if( get(this.survey.strings, path) ) {
    //   return this.survey.strings[this.$i18n.locale][string]
    // } else {

    // }
  }

  load() {
    if (this.surveyId) {
      axios
        .get(
          process.env.VUE_APP_API2 + "/cs/" + this.surveyId + "/" + this.itemId
        )
        .then((response) => {
          // console.log(response.data);
          this.survey = response.data;
          if (!this.survey.options) {
            this.survey.options = {};
          }
          this.thematics = Object.keys(this.survey.tree);
          // take care of locale/lang here
          if (
            this.survey.default_locale &&
            this.$i18n.availableLocales.indexOf(this.survey.default_locale)
          ) {
            this.$i18n.locale = this.survey.default_locale;
          }
          this.survey.options.polarity_click =
            !!this.survey.options.polarity_click;
          // console.log("Polarity click: ", this.survey.options.polarity_click);
          if(this.survey.options.lang_select && !this.param_lang) {
            this.step = 'lang_select';
          }
          this.ready = true;
        });
    } else {
      this.step = "nodata";
    }

    this.$i18n.messages.nl.intro = "overidding message does not work";
    console.log("i18n: ", this.$i18n.messages);
  }

  incClicks() {
    this.totalClicks++;
  }

  setLang(lang: string) {
    this.incClicks();
    this.$i18n.locale = lang;
    this.step = "hello";
  }

  hello() {
    this.incClicks();
    this.start();
  }

  start() {
    this.startTime = Date.now();
    this.step = "them";
    this.uuid = createUUID();
  }

  themClick(them: string) {
    this.incClicks();
    this.current.them = them;
    this.step = "top";
  }

  topClick(top: string) {
    this.incClicks();
    this.current.top = top;
    this.step = "sub";
  }

  subClick(sub: string, sent: boolean) {
    this.incClicks();
    this.current.sub = sub;
    this.current.sent = sent ? 1 : -1;
    this.matches.push(this.current);
    // uniques thru Set not working without going to string - ugly but working
    // @ts-ignore
    const set = new Set(this.matches.map(JSON.stringify));
    // @ts-ignore
    const stringsArray = Array.from(set);
    // @ts-ignore
    this.matches = stringsArray.map(JSON.parse);
    this.step = "recap";
  }

  textsave() {
    this.incClicks();
    this.step = "recap";
  }

  // cancel last
  cancel() {
    this.incClicks();
    this.current = {};
    this.matches.pop();
    this.step = "them";
  }

  restart() {
    this.totalClicks = 0;
    this.current = {};
    this.endTime = this.startTime = this.timing = 0;
    this.matches = [];
    // this.start();
    // this.step = "hello";
    this.start();
  }

  // return to them
  next() {
    this.incClicks();
    this.current = {};
    this.step = "them";
  }

  // back one step - only available in top and sub
  back(to: string) {
    console.log("back", to);
    this.incClicks();
    if (to == "them") {
      delete this.current.them;
      this.step = "them";
    } else if (to == "top") {
      delete this.current.top;
      this.step = "top";
    }
  }

  alreadyMatched(them: string, top: string, sub: string) {
    var res = false;
    this.matches.forEach((element) => {
      if (
        element &&
        element != {} &&
        element.them == them &&
        element.top == top &&
        element.sub == sub
      ) {
        res = true;
        return false;
      }
    });
    return res;
  }

  totext() {
    this.step = "opentext";
  }

  mounted() {
    this.suuid = createUUID();
    this.load();
  }

  end() {
    // this.matches = [];
    this.endTime = Date.now();
    this.timing = this.endTime - this.startTime;
    // send data with axios in the right item with the right metadata
    const postData = {
      matches: this.matches,
      // lang: this.lang,
      totalClicks: this.totalClicks,
      startTime: this.startTime,
      endTime: this.endTime,
      timing: this.endTime - this.startTime,
      uuid: this.uuid,
      suuid: this.suuid,
      locale: this.$i18n.locale,
      opentext: this.opentext,
    };
    console.log("sending data: ", postData);
    axios
      .post(
        process.env.VUE_APP_API2 + "/csr/" + this.surveyId + "/" + this.itemId,
        postData
      )
      .then((response) => {
        console.log(response.data);
      });
    this.step = "end";
  }

  // get data from url - we dont have vue-router here
  created() {
    let uri = window.location.search.substring(1);
    let urlParams = new URLSearchParams(uri);

    this.surveyId = urlParams.get("s") ? urlParams.get("s") : undefined;
    this.itemId = urlParams.get("i") ? urlParams.get("i") : undefined;
    // @ts-ignore
    this.param_lang = urlParams.get("l")
    this.$i18n.locale = urlParams.get("l") ? urlParams.get("l")! : "en"
    this.debug = !!urlParams.get("d");
    // console.log("def: ",this.$i18n.default_locale,this.$i18n.locale, this.params );

  }

  topics() {
    return Object.keys(this.survey.tree[this.current.them]);
  }
  subtopics() {
    return this.survey.tree[this.current.them][this.current.top];
  }

  meta() {
    return [{ name: "description", content: this.itemId }];
  }
}
