const antonyms: object = {
    "accessible":"inaccessible",
    "affordable":"costly",
    "attentive":"inattentive",
    "attractive":"unattractive",
    "authentic":"fake",
    "automatic":"manual",
    "available":"unavailable",
    "beautiful":"ugly",
    "big":"small",
    "central":"outer",
    "cheap":"expensive",
    "clean":"dirty",
    "clear":"unclear",
    "comfortable":"uncomfortable",
    "complete":"partial",
    "convenient":"inconvenient",
    "correct":"wrong",
    "cozy":"uncomfortable",
    "delicious":"inedible",
    "easy":"difficult",
    "efficient":"inefficient",
    "enough":"insufficient",
    "excellent":"poor",
    "famous":"unknown",
    "fast":"slow",
    "free":"paying",
    "fresh":"old",
    "friendly":"unfriendly",
    "fun":"boring",
    "good":"bad",
    "great":"not great",
    "handy":"inconvenient",
    "healthy":"unhealthy",
    "helpful":"unhelpful",
    "hot":"cold",
    "large":"small",
    "luminous":"dark",
    "modern":"past",
    "multi":"mono",
    "nice":"unpleasant",
    "normal":"abnormal",
    "perfect":"imperfect",
    "quiet":"noisy",
    "reasonable":"unreasonable",
    "right":"wrong",
    "safe":"unsafe",
    "short":"long",
    "special":"ordinary",
    "strong":"weak",
    "tasty":"bland",
    "temperate":"extreme",
    "uncrowded":"crowded",
    "warm":"cold",
    "wide":"limited",
    "worth":"unworthy",
}

module.exports = antonyms;
