export enum Locales {
    EN = 'en',
    FR = 'fr',
    NL = 'nl',

}

export const LOCALES = [
    { value: Locales.EN, caption: 'English' },
    { value: Locales.FR, caption: 'Français' },
    { value: Locales.NL, caption: 'Nederlands' },
]